.spinner-container {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
}
.spinner {
	position: absolute;
	left: calc(50% - 30px); // Accounting for the navbar 
	top: 50%;
	height:60px;
	width:60px;
	margin:0px auto;
	-webkit-animation: rotation .6s infinite linear;
	-moz-animation: rotation .6s infinite linear;
	-o-animation: rotation .6s infinite linear;
	animation: rotation .6s infinite linear;
	border-left:6px solid $spinner-background;
	border-right:6px solid $spinner-background;
	border-bottom:6px solid $spinner-background;
	border-top:6px solid $spinner-color;
	border-radius:100%;
  }
  
  @-webkit-keyframes rotation {
	 from {-webkit-transform: rotate(0deg);}
	 to {-webkit-transform: rotate(359deg);}
  }
  @-moz-keyframes rotation {
	 from {-moz-transform: rotate(0deg);}
	 to {-moz-transform: rotate(359deg);}
  }
  @-o-keyframes rotation {
	 from {-o-transform: rotate(0deg);}
	 to {-o-transform: rotate(359deg);}
  }
  @keyframes rotation {
	 from {transform: rotate(0deg);}
	 to {transform: rotate(359deg);}
  }
