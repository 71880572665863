/********* Colors *********/

$background-color: #F6F8F9;
$error-color: #b10b0b; 

$border-color: #dcdada;
$text-color: #303030;

$spinner-background: rgba(48,60,55,.15);
$spinner-color: rgba(48,60,55,.8);

$crunchorder-dark: #292e42;
$crunchorder-blue: #6B75FA;

/* Fonts */
@font-face { font-family: 'Gotham Rounded'; src: url("../fonts/Gotham-Rounded/GothamRnd-Bold.otf") format("opentype"); font-weight:700; }
@font-face { font-family: 'Gotham Rounded'; src: url("../fonts/Gotham-Rounded/GothamRnd-Medium.otf") format("opentype"); font-weight:500; }
@font-face { font-family: 'Gotham Rounded'; src: url("../fonts/Gotham-Rounded/GothamRnd-Book.otf") format("opentype"); font-weight:300; }
@font-face { font-family: 'Gotham Rounded'; src: url("../fonts/Gotham-Rounded/GothamRnd-Light.otf") format("opentype"); font-weight:100; }
 
$main-font: "Roboto", sans-serif; 
$accent-font: "Gotham Rounded", "Oswald", sans-serif;