.list {
    width: 100%;

    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    margin-top: 2rem;

    display: none;

    @include md {
        display: flex;
    }

    .list-item {
        position: relative;
        margin: 0.5rem;
        min-width: 15rem;
        width: 15%;
        width: calc(20% - 1rem);
        background-color: white;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 10px;

        .order {
            padding: 0.5rem 1rem;
            border-radius: 8px;

            &.disabled {
                background-color: #c6c6c6;
                opacity: 0.5;
            }
        }

        .order-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 0.5rem;

            .order-header-left {

                .order-info-text {
                    margin: 0;
                }
                .order-ready-time {
                    font-weight: bold;
                }
                .order-name {
                    font-weight: bold;
                }
                .order-table {
                    font-weight: bold;
                }
                .order-time-counter {
                    font-weight: 100;
                    font-size: 14px;
                    margin-left: 6px;
                    color: #9d9d9d;
                }

                .new-order-highlight {
                    font-size: 18px;

                    margin-left: 1rem;
                    padding: 5px 12px;

                    color: #2dc063;
                    border: 1px solid #2dc063;
                    border-radius: 8px;
                }
            }

            .order-header-right {
                display: flex;

                .button {
                    padding: 8px 12px;
                }
            }
        }

        .order-receipt-number-container {
            .order-receipt-number {
                margin: 0;
                color: #9d9d9d;
                font-size: 14px;
            }
        }

        .order-body {
            margin-top: 1rem;
            padding-bottom: 4rem;

            .order-element-container {
                display: flex;
                align-items: flex-start;
                margin: 1rem 0;
            }
        }

        .order-run-summary {
            position: absolute;
            width: 100%;
            bottom: 0;
            margin: 0 -1rem;
            background-color: $crunchorder-blue;

            border-radius: 0 0 8px 8px;

            .order-run-summary-text {
                text-align: center;
                margin: 8px 0;
                font-size: 13px;
                font-weight: bold;
                color: white;
            }
        }
    }
}

.order-comment {
    margin-top: -2rem;
    padding-bottom: 2rem;

    p {
        margin: 5px;
    }
    .order-comment-title {
        font-weight: bold;
    }
}

.empty-ingredient-list {
    text-align: center;

    .empty-ingredient-list-text {
        font-style: italic;
    }

    .confirmation-buttons {
        display: flex;
        justify-content: space-around;
    }
}