.header-section {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px;

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .header-title-section {
            padding: 1.5rem 2rem;

            .header-title {
                display: flex;
                align-items: center;
            }
            .title-customer-name {
                font-size: 0.5em;
                font-style: italic;
                font-weight: 100;
                margin-left: 1rem;
            }
        }

        .header-information-section {
            padding: 0 1rem 0 0;
            
            justify-content: flex-end;
            align-items: center;

            display: none;

            @include md {
                display: flex;
            }

            .header-item {
                margin: 0 0 0 1.5rem;

                &.product-count {
                    color: #9d9d9d;
                    font-style: italic;
                }
            }
        }

        .filter-icon {
            background-image: url("../../img/filter.png");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            height: 2rem;
            width: 2rem;
            cursor: pointer;
        }
    }

    .header-filter-section {
        padding: 1rem 0.5rem;
        background-color: white;
        border-top: 1px solid #dfdfdf;
        
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .filter-item {
            margin: 0 0.5rem;
        }

        .time-selector-section {
            min-width: 10rem;
        }
    }
}