.instance-selector {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    display: none;

    @include md {
        display: flex;
    }

    .instance-container {
        padding: 1rem 4rem;
        margin: 0 1rem;
        background-color: white;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 15px;
        cursor: pointer;

        .instance-name {
            font-weight: bold;
        }
    }
}