@import url('https://fonts.googleapis.com/css?family=Oswald|Roboto+Slab|Roboto&display=swap');

html, body {
    color: $text-color;
    margin:0;
    background-color: $background-color;
    -webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

#root {
    min-height: 100vh;
    touch-action: manipulation;

    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    font-family: $main-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
    margin:0;
    font-family: $accent-font;
    user-select: none;
}

p, span, div {
    user-select: none;
}

a {
    font-weight: bold;
    color: $crunchorder-dark;
    text-decoration: none;
}

button {
    font-family: $main-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    text-align: center;
    border-radius: 6px;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

.button {
	background: transparent;
    border: 1px solid $crunchorder-blue;
    color: $crunchorder-blue;
    font-weight: bold;
    text-decoration: none;
    line-height: 1;
    font-size: 15px;
    padding: 10px 18px;

    &.large-button {
        padding: 15px 25px; 
        font-size: 16px;
    }

    &.error-button {
        background-color: transparent;
        border-color: $error-color;
        color: $error-color;
        font-weight: normal;
    }

    &.muted-button {
        background-color: $border-color;
        border-color: $border-color;
        color: $text-color;
        font-weight: normal;
    }

    &[disabled] {
        opacity: 0.6;
        cursor: initial;
    }
}