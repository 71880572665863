/********** Breakpoints **********/

$small:  767px;
$medium: 992px;
$large:  1200px;

@mixin sm {
   @media (max-width: #{$small}) {
       @content;
   }
}

@mixin md {
   @media (min-width: #{$medium}) {
       @content;
   }
}

@mixin lg {
   @media (min-width: #{$large}) {
       @content;
   }
}