.element {
    margin: 0 0 0 0.5rem;

    .element-name {
        margin: 0;
    }

    .element-child-container {
        margin-top: 8px;

        .element-child-child-container {
            display: flex;
            align-items: center;

            .checkmark {
                width: 18px;
                height: 18px;
            }
            .checkbox {
                input[type="checkbox"] + div.checkmark-container {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        .element-child-child {
            margin: 0;
            font-size: 12px;
        }
    }
}