#root {
    display: flex;
    flex-direction: column;
}

.main {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
}